const COLORS_BAR_LEGEND = ['#FFF4DB', '#00B7FF', '#007AFF', '#0000FF', '#0000BA', '#00007C']
const COLORS_DOT_LEGEND = ['#00B7FF', '#007AFF', '#FA00FF']

const SELECT_VALUES_IT = [
  {
    option: 'In Italia',
    value: '',
  },
]

const SELECT_VALUES = [
  {
    option: "All'estero",
    value: '',
  },
  {
    option: 'In Europa',
    value: 'Europa',
  },
  {
    option: 'In Africa',
    value: 'Africa',
  },
  {
    option: 'In America Latina',
    value: 'America Latina',
  },
  {
    option: 'In Asia',
    value: 'Asia',
  },
]

// Small devices (landscape phones, 576px and up)
// export const SD = '(min-width: 576px)'

// Medium devices (tablets, 768px and up)
// export const MD = '(min-width: 768px)'

// Large devices (desktops, 992px and up)
// export const LD = '(min-width: 992px)'

// Extra large devices (large desktops, 1200px and up)
// export const XLD = '(min-width: 1200px)'

module.exports.conf = {
  colorsBarLegend: COLORS_BAR_LEGEND,
  colorsDotLegend: COLORS_DOT_LEGEND,
  selectValues: SELECT_VALUES,
  selectValuesIT: SELECT_VALUES_IT,
}
