<script>
import LinkBtn from './LinkBtn.svelte'
const href = "//missionbambini.org/wp-content/uploads/Annual_Report_2022_Mission_Bambini.pdf"
</script>

<div class="header">
  <img class="logo" src={'./assets/logo-header.svg'} alt="Logo Mission Bambini" />
  <LinkBtn header="true" text="Scarica il Report" color="#00007C" backgroundColor="#FFD302" href={href}/>
</div>

<style>
.header {
  position: fixed;
  height: 3.9375rem;
  background-color: #00007c;
  width: 100%;
  z-index: 1;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
