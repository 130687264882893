<script>
import { scaleLinear } from 'd3-scale'
import { animateWidth } from '../lib/custom-transition.js'
export let isVisible
export let color
export let text
export let data
let isVisibleOnce = isVisible
$: isVisibleOnce = isVisibleOnce || isVisible
const xMax = Math.max(...data.map((d) => d.value))
const x = scaleLinear().domain([0, xMax]).range([0, 100])
</script>

<div class="wrapper">
  {#if text}
    <p class="intro" class:visible={isVisible}>{text}</p>
  {/if}

  <div class="charts">
    {#each data as d, i}
      <div class="chart">
        {#if isVisibleOnce}
          <p>{d.label}</p>
          <div class="bar-wrapper">
            <div
              class="bar"
              in:animateWidth={{ delay: (i + 1) * 70, duration: 500 }}
              data-width-perc={x(d.value)}
              style="width: 0; background: {color}" />
            <span>{d.value}</span>
          </div>
        {/if}
      </div>
    {/each}
  </div>
</div>

<style>

.wrapper{
  min-height: 324px;
}

.intro {
  font-size: var(--font-title-xs);
  line-height: 1.5rem;
  font-weight: 700;
  transition: 0.5s all ease-in-out;
  /* opacity: 0; */
  /* transform: translate(0, 20px); */
}
.intro.visible {
  opacity: 1;
  transform: none;
}
.charts {
  padding: 1rem 0 0 0; 
}

.bar-wrapper {
  display: flex;
  align-items: center;
}
.bar {
  height: 1.5rem;
  margin-bottom: 0.375rem;
  margin-right: 0.5rem;
  border: 2px solid #ffc129;
  box-sizing: border-box;
  border-radius: 4px;
}
.bar-wrapper span {
  position: relative;
  transform: translate(0, -0.2rem);
  font-weight: 700;
}
.bar-wrapper span,
.chart p {
  font-size: 0.875rem;
  line-height: 1.4;
}
</style>
