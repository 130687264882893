module.exports.data = {
  hero: {
    title: 'Annual Report 2022',
    video: {
      src: 'assets/videoHeroDesktop.mp4',
      mobileSrc: 'assets/videoHeroMobile.mp4',
      cover: true,
    },
  },
  sections: [
    {
      backgroundColor: '#FFDB48',
      ref: 'risultati',
      intersectionObserver: { threshold: 0.5, once: true },
      fullWidth: true,
      modules: [
        {
          type: 'h2',
          text: 'I risultati del 2022',
          map: true,
        },
        { type: 'map' },
        { type: 'projects-grid' },
      ],
    },
    {
      ref: 'risorse',
      modules: [
        {
          type: 'h2',
          text: 'Le risorse del',
          highlight: '2022',
        },
        {
          type: 'animated-number',
          number: 46366,
          cents: false,
          duration: 1000,
          center: true,
          description: 'donazioni',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'p',
          text: 'grazie a',
          center: true,
        },
        {
          type: 'animated-number',
          number: 17158,
          cents: false,
          duration: 1000,
          center: true,
          description: 'donatori',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 600,
          cents: false,
          duration: 1000,
          center: true,
          description: 'volontari',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'p',
          text: 'per un totale di',
          center: true,
        },
        {
          type: 'animated-number',
          number: 7230,
          cents: false,
          duration: 1000,
          center: true,
          description: 'ore donate',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'barChart',
          color: '#FFDB48',
          text: 'Volontari per tipologia di attività',
          data: [
            {
              label: 'Eventi di sensibilizzazione e raccolta fondi',
              value: 345,
            },
            { label: 'Attività di formazione', value: 132 },
            { label: 'Volontariato di sede', value: 43 },
            { label: 'Educazione Italia bambini e ragazzi 6-16 anni', value: 23 },
            { label: 'Emergenza Ucraina', value: 19 },
            { label: 'Educazione Italia bambini 0-6 anni', value: 19 },
            { label: 'Volontariato internazionale', value: 11 },
            { label: 'Cuore di bimbi Italia', value: 8 },
          ],
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 127,
          cents: false,
          duration: 1000,
          center: true,
          description: 'aziende partner',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'donut',
          title: 'Fondi raccolti da aziende per tipologia di attività (%)',
          data: [
            { label: 'CSR Corporate Social Responsibility', color: '#DD9E2F', value: 57 },
            { label: 'Donazioni in kind', color: '#FFC129', value: 27 },
            { label: 'CRM Cause Related Marketing', color: '#FFD302', value: 9 },
            { label: 'Employee engagement', color: '#FFF4DB', value: 7 },
          ],
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 12,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Fondazioni ed enti partner',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'donut',
          title: 'Fondi raccolti da fondazioni o altri enti per tipologia di ente (%)',
          data: [
            { label: 'Fondazioni bancarie', color: '#DD9E2F', value: 64 },
            {
              label: 'Fondazioni d’impresa',
              color: '#FFC129',
              value: 16,
            },
            {
              label: 'Altre Fondazioni o altri enti del Terzo Settore',
              color: '#FFD302',
              value: 15,
            },
            { label: 'Enti pubblici', color: '#FFF4DB', value: 5 },
          ],
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 30,
          cents: false,
          duration: 1000,
          center: true,
          description: 'collaboratori',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'barChart',
          color: '#FFDB48',
          text: "Collaboratori per fascia d'età",

          data: [
            {
              label: '20-29 anni',
              value: 2,
            },
            { label: '30-39 anni', value: 13 },
            { label: '40-49 anni', value: 14 },
            { label: '50 anni e più', value: 1 },
          ],
        },
      ],
    },
    {
      ref: 'impegnodal2000',
      modules: [
        {
          type: 'h2',
          text: 'Il nostro impegno dal',
          highlight: '2000',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          bold: true,
          text: 'progetti educazione',
        },
        {
          type: 'p',
          text: 'Obiettivo: contrastare la povertà educativa minorile garantendo l’accesso a percorsi di istruzione, educazione e formazione di qualità, anche a supporto dell’inclusione lavorativa e sociale, in Italia e all’estero.',
          onIntersect: { fromBottom: true },
        },
        {
          type: 'p',
          box: true,
          text: 'La povertà educativa è la condizione in cui un bambino o un adolescente si trova privato del diritto all’apprendimento: dalle opportunità culturali ed educative al diritto al gioco. Povertà economica e povertà educativa si alimentano a vicenda.',
          onIntersect: { fromBottom: true },
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: 'progetti educazione in italia / 0-6 anni',
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              style: 'cover',
              alt: 'bambini stelle',
              src: 'assets/Carosello01_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_01-1440-1x.jpg',
                  x2: 'assets/Carosello01_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_01-1024-1x.jpg',
                  x2: 'assets/Carosello01_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_01-768-1x.jpg',
                  x2: 'assets/Carosello01_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_01-320-1x.jpg',
                  x2: 'assets/Carosello01_01-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'tre bambine',
              src: 'assets/Carosello01_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_02-1440-1x.jpg',
                  x2: 'assets/Carosello01_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_02-1024-1x.jpg',
                  x2: 'assets/Carosello01_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_02-768-1x.jpg',
                  x2: 'assets/Carosello01_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_02-320-1x.jpg',
                  x2: 'assets/Carosello01_02-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'stella mission bambini',
              src: 'assets/Carosello01_03.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_03-1440-1x.jpg',
                  x2: 'assets/Carosello01_03-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_03-1024-1x.jpg',
                  x2: 'assets/Carosello01_03-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_03-768-1x.jpg',
                  x2: 'assets/Carosello01_03-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_03-320-1x.jpg',
                  x2: 'assets/Carosello01_03-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'bambin e donna',
              src: 'assets/Carosello01_04.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_04-1440-1x.jpg',
                  x2: 'assets/Carosello01_04-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_04-1024-1x.jpg',
                  x2: 'assets/Carosello01_04-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_04-768-1x.jpg',
                  x2: 'assets/Carosello01_04-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_04-320-1x.jpg',
                  x2: 'assets/Carosello01_04-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'star',
          number: 17545,
        },
        {
          type: 'animated-number',
          number: 17545,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'bambini beneficiari di servizi educativi per la prima infanzia e sostegno alle famiglie',
          label: "Fascia d'età 0-6 anni",
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/scintilla-prima-infanzia-mission-bambini/',
        },
      ],
    },

    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: 'progetti educazione in italia / 6-16 anni',
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'bambini e ragazza',
              src: 'assets/Carosello02_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello02_01-1440-1x.jpg',
                  x2: 'assets/Carosello02_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello02_01-1024-1x.jpg',
                  x2: 'assets/Carosello02_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello02_01-768-1x.jpg',
                  x2: 'assets/Carosello02_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello02_01-320-1x.jpg',
                  x2: 'assets/Carosello02_01-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'salto',
              src: 'assets/Carosello02_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello02_02-1440-1x.jpg',
                  x2: 'assets/Carosello02_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello02_02-1024-1x.jpg',
                  x2: 'assets/Carosello02_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello02_02-768-1x.jpg',
                  x2: 'assets/Carosello02_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello02_02-320-1x.jpg',
                  x2: 'assets/Carosello02_02-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'bambino e matita',
              src: 'assets/Carosello02_03.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello02_03-1440-1x.jpg',
                  x2: 'assets/Carosello02_03-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello02_03-1024-1x.jpg',
                  x2: 'assets/Carosello02_03-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello02_03-768-1x.jpg',
                  x2: 'assets/Carosello02_03-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello02_03-320-1x.jpg',
                  x2: 'assets/Carosello02_03-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'pennarelli',
              src: 'assets/Carosello02_04.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello02_04-1440-1x.jpg',
                  x2: 'assets/Carosello02_04-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello02_04-1024-1x.jpg',
                  x2: 'assets/Carosello02_04-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello02_04-768-1x.jpg',
                  x2: 'assets/Carosello02_04-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello02_04-320-1x.jpg',
                  x2: 'assets/Carosello02_04-320-2x.jpg',
                },
              ],
            },
          ],
        },

        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'square',
          number: 24977,
          caption: 'bambini e ragazzi aiutati',
        },
        {
          type: 'animated-number',
          number: 24977,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'bambini, ragazze e ragazzi aiutati tramite sostegno allo studio e progetti innovativi nelle scuole',
          label: "Fascia d'età 6-16 anni",
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/stringhe-un-passo-avanti-per-contrastare-la-poverta-educativa-e-la-segregazione-scolastica/',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: 'progetti educazione in italia / 16-24 anni',
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'documentazione',
              src: 'assets/Carosello03_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello03_01-1440-1x.jpg',
                  x2: 'assets/Carosello03_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello03_01-1024-1x.jpg',
                  x2: 'assets/Carosello03_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello03_01-768-1x.jpg',
                  x2: 'assets/Carosello03_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello03_01-320-1x.jpg',
                  x2: 'assets/Carosello03_01-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'ragazzi formazione',
              src: 'assets/Carosello03_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello03_02-1440-1x.jpg',
                  x2: 'assets/Carosello03_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello03_02-1024-1x.jpg',
                  x2: 'assets/Carosello03_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello03_02-768-1x.jpg',
                  x2: 'assets/Carosello03_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello03_02-320-1x.jpg',
                  x2: 'assets/Carosello03_02-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'square',
          number: 1507,
          caption: 'giovani aiutati',
        },
        {
          type: 'animated-number',
          number: 1507,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'giovani aiutati attraverso percorsi di formazione o di inserimento lavorativo',
          label: "Fascia d'età 16-14 anni",
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/allenamenti-per-il-futuro-unopportunita-per-i-giovani-neet/',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: "progetti educazione all'estero",
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              style: 'cover',
              alt: 'bambini grembiule',
              src: 'assets/Carosello04_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello04_01-1440-1x.jpg',
                  x2: 'assets/Carosello04_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello04_01-1024-1x.jpg',
                  x2: 'assets/Carosello04_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello04_01-768-1x.jpg',
                  x2: 'assets/Carosello04_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello04_01-320-1x.jpg',
                  x2: 'assets/Carosello04_01-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'ragazze e bambini',
              src: 'assets/Carosello04_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello04_02-1440-1x.jpg',
                  x2: 'assets/Carosello04_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello04_02-1024-1x.jpg',
                  x2: 'assets/Carosello04_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello04_02-768-1x.jpg',
                  x2: 'assets/Carosello04_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello04_02-320-1x.jpg',
                  x2: 'assets/Carosello04_02-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'bambini pavimento',
              src: 'assets/Carosello04_03.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello04_03-1440-1x.jpg',
                  x2: 'assets/Carosello04_03-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello04_03-1024-1x.jpg',
                  x2: 'assets/Carosello04_03-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello04_03-768-1x.jpg',
                  x2: 'assets/Carosello04_03-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello04_03-320-1x.jpg',
                  x2: 'assets/Carosello04_03-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'ragazza turbante',
              src: 'assets/Carosello04_04.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello04_04-1440-1x.jpg',
                  x2: 'assets/Carosello04_04-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello04_04-1024-1x.jpg',
                  x2: 'assets/Carosello04_04-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello04_04-768-1x.jpg',
                  x2: 'assets/Carosello04_04-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello04_04-320-1x.jpg',
                  x2: 'assets/Carosello04_04-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'square',
          number: 31984,
          caption: 'bambini e ragazzi aiutati',
        },
        {
          type: 'animated-number',
          number: 31984,
          cents: false,
          duration: 1000,
          center: true,
          width: 50,
          description:
            'bambini, ragazze e ragazzi aiutati tramite progetti di educazione e accoglienza',
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/cosa-facciamo/educazione/educazione-estero',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: "progetto borse rosa all'estero",
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'bambine vestiti rosa',
              src: 'assets/Carosello05_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello05_01-1440-1x.jpg',
                  x2: 'assets/Carosello05_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello05_01-1024-1x.jpg',
                  x2: 'assets/Carosello05_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello05_01-768-1x.jpg',
                  x2: 'assets/Carosello05_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello05_01-320-1x.jpg',
                  x2: 'assets/Carosello05_01-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'tre bambini',
              src: 'assets/Carosello05_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello05_02-1440-1x.jpg',
                  x2: 'assets/Carosello05_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello05_02-1024-1x.jpg',
                  x2: 'assets/Carosello05_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello05_02-768-1x.jpg',
                  x2: 'assets/Carosello05_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello05_02-320-1x.jpg',
                  x2: 'assets/Carosello05_02-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'circle',
          number: 2409,
          caption: 'ragazze aiutate',
        },
        {
          type: 'animated-number',
          number: 2409,
          cents: false,
          duration: 1000,
          center: true,
          description: 'ragazze aiutate con percorsi di istruzione secondaria e terziaria',
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/empowerment-femminile-quando-listruzione-e-una-scelta-che-dura-una-vita/',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          bold: true,
          text: 'progetti salute',
        },
        {
          type: 'p',
          text: 'Obiettivo: promuovere la prevenzione e garantire l’accesso a cure tempestive per tutelare la salute di bambini e ragazzi, con particolare riferimento a minorenni affetti da cardiopatie infantili nei Paesi con un sistema sanitario carente.',
          onIntersect: { fromBottom: true },
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'bambino palloncino',
              src: 'assets/Carosello06_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello06_01-1440-1x.jpg',
                  x2: 'assets/Carosello06_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello06_01-1024-1x.jpg',
                  x2: 'assets/Carosello06_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello06_01-768-1x.jpg',
                  x2: 'assets/Carosello06_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello06_01-320-1x.jpg',
                  x2: 'assets/Carosello06_01-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'medici sala operatoria',
              src: 'assets/Carosello06_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello06_02-1440-1x.jpg',
                  x2: 'assets/Carosello06_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello06_02-1024-1x.jpg',
                  x2: 'assets/Carosello06_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello06_02-768-1x.jpg',
                  x2: 'assets/Carosello06_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello06_02-320-1x.jpg',
                  x2: 'assets/Carosello06_02-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'bambino ospedale',
              src: 'assets/Carosello06_03.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello06_03-1440-1x.jpg',
                  x2: 'assets/Carosello06_03-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello06_03-1024-1x.jpg',
                  x2: 'assets/Carosello06_03-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello06_03-768-1x.jpg',
                  x2: 'assets/Carosello06_03-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello06_03-320-1x.jpg',
                  x2: 'assets/Carosello06_03-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'medico stetoscopio',
              src: 'assets/Carosello06_04.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello06_04-1440-1x.jpg',
                  x2: 'assets/Carosello06_04-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello06_04-1024-1x.jpg',
                  x2: 'assets/Carosello06_04-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello06_04-768-1x.jpg',
                  x2: 'assets/Carosello06_04-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello06_04-320-1x.jpg',
                  x2: 'assets/Carosello06_04-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'star',
          number: 2553,
          fill: '#007AFF',
          stroke: '#0000FF',
        },
        {
          type: 'animated-number',
          number: 2553,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'bambini operati al cuore, oltre a 23.415 bambini visitati, 546 medici locali formati e 100 professionisti sanitari volontari grazie al Programma “Cuore di bimbi”',
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
        },
      ],
    },
    {
      backgroundColor: '#FFDB48',
      intersectionObserver: { threshold: 0.5, once: true },
      fullWidth: true,
      modules: [
        {
          type: 'p',
          text: 'Mission Bambini ETS (Ente del Terzo Settore) è una fondazione nata in Italia nel 2000 per iniziativa dell’Ingegner Goffredo Modena e della sua famiglia. A inizio 2015 la Fondazione italiana ha dato vita a Mission Bambini Switzerland e a Friends of Mission Bambini fund negli USA. In 23 anni di attività il Gruppo Mission Bambini ha sostenuto oltre 1.400.000 bambini attraverso più di 2.000 progetti di aiuto in 77 Paesi del mondo.',
          center: true,
        },
        {
          type: 'btn',
          text: 'Scarica il Report',
          href: '//missionbambini.org/wp-content/uploads/Annual_Report_2022_Mission_Bambini.pdf',
        },
      ],
    },
  ],
  preFooter: {},
}
