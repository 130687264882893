<script>
import { onMount } from 'svelte'
import areas from '../data/map_NUMBERS.json'
import mapData from '../data/map_MAIN.json'
import MapBtn from './MapBtn.svelte'
import MapLegend from './MapLegend.svelte'
import AnimatedNumber from './AnimatedNumber.svelte'
import Module from './Module.svelte'
import MapChart from './MapChart.svelte'
import { conf } from '../lib/const';

const EUROPA_COUNTRIES= ["Albania", "France", "Spain", "Germany", "Switzerland", "Austria", "Slovak_Republic", "Hungary", "Serbia", "Romania", "Russian_Federation", "Bulgaria", "Greece", "Macedonia", "Algeria", "Tunisia", "Czech_Republic", "Montenegro", "Ukraine", "Poland", "Turkey", "Moldova", "Bosnia", "Croatia", "Slovenia", "Kosovo"]

let clicked = false
let current = areas ? areas[0] : {}
let SVGMAP
let CENTROIDS  
let EUROPA_FILL

const cleanId = (id) => id.split('Point')[0].trim()
const getValueByCountryId = (cId) => mapData.find(d => d.country === cId)?.value
const getHexByValue = (cId) => getValueByCountryId(cId) >= 5 ? conf.colorsBarLegend[5] : conf.colorsBarLegend[getValueByCountryId(cId)] 


const removeCountries = (r) => {
  if (!SVGMAP) return  
  if (r === 'Italia') {
    setTimeout(() => EUROPA_COUNTRIES.map(c => SVGMAP.querySelector(`#${c}`).setAttribute('fill', '#FFDB48')), 300)
  } else {    
    EUROPA_COUNTRIES.map(c => {
      SVGMAP.querySelector(`#${c}`).setAttribute('fill', EUROPA_FILL[c].fill) 
    })
  }   
}

const toogleRegion = (on) => {  
  if (!SVGMAP) return 
  SVGMAP.querySelector('#Italia-Regioni').setAttribute('visibility', on ? 'visibile' : 'hidden')
  SVGMAP.querySelector('#Italy').setAttribute('visibility', !on ? 'visibile' : 'hidden')  
} 

onMount(() => {
  const cEl = Array.from(SVGMAP.querySelector('#Centroidi').children)
  toogleRegion(false)
  
  EUROPA_FILL = EUROPA_COUNTRIES.reduce((acc, c) => {
  if (!SVGMAP) return
  acc[c] = {  
    fill: SVGMAP.querySelector(`#${c}`).getAttribute('fill')
  }
  return acc
}, {}) 

  CENTROIDS = cEl.reduce((a, c) => ({
    ...a,
    [c.id]: Array.from(c.children).map(r => ({        
      id: cleanId(r.id),
      cx: r.getAttributeNode('cx').value,
      cy: r.getAttributeNode('cy').value
    })) 
  }), {})  
}) 

$: {
  removeCountries(current.regione)
  toogleRegion(current.regione === 'Italia')
}

</script>

<div class="wrapper">
  <div class="map-legend"><MapLegend /></div>
  <div class="map-frame">
    <div class="map-container">
      <svg
        class="map-chart"
        style="transform: translate({current['translate']}) scale({current['scale']})"
        fill="none"
        bind:this={SVGMAP}
        xmlns="http://www.w3.org/2000/svg">        
        <MapChart />
        {#if current && CENTROIDS && current.regione!='Mondo'}
          {#each CENTROIDS[current.regione] as c}                     
          {@const s = current.regione === 'Italia' || current.regione === 'Europa' ? {
            r: 2,
            stroke: 0.3,
            fontSize: 2,
            offset: 0.6
          } : {
            r: 7,
            stroke: 1,
            fontSize: 8,
            offset: 3
          }}
          <g>
              <circle cx={c.cx} cy={c.cy} r={s.r} stroke="#FFF" stroke-width={s.stroke} style="fill: {getHexByValue(c.id)}; filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .5))"/>
              <text x={c.cx} y={parseFloat(c.cy) + s.offset} text-anchor="middle" fill="white" font-size={s.fontSize}>{getValueByCountryId(c.id)}</text>
            </g>
          {/each}
        {/if}
      </svg>
    </div>
  </div>
  <div class="map-btn"><MapBtn bind:current bind:clicked {areas} /></div>

  <div class="map-numbers">
    <Module
      moduleType={AnimatedNumber}
      module={{
        number: current['bambiniAiutati'],
        duration: 1000,
        color: '#0000BA',        
        unstyle: true,
        description: 'Bambini aiutati',
        clicked: clicked,
        descrColor: '#00007C',
      }} />

    <Module
      moduleType={AnimatedNumber}
      module={{
        number: current['progettiRealizzati'],
        duration: 1000,
        color: '#0000BA',        
        unstyle: true,
        description: 'Progetti realizzati',
        clicked: clicked,
        descrColor: '#00007C',
      }} />

    <Module
      moduleType={AnimatedNumber}
      module={{
        number: current['paesiInternvento'],
        duration: 1000,
        color: '#0000BA',        
        unstyle: true,
        description: current.regione === 'Italia' ? "Regioni d'intervento" : "Paesi d'intervento",
        clicked: clicked,
        descrColor: '#00007C',
      }} />
  </div>
</div>

<style>
.wrapper {
  margin: 0 auto;
}
.map-frame {
  overflow: hidden;
  margin: var(--margin-xs) 0;
}
.map-container {
  transform: scale(0.9);
  transition: all 700ms;
  display: flex;
  justify-content: center;
}
.map-chart {
  transition: all 700ms;
  width: 768px;
  height: 413px;
  flex: 0 0 auto;
}
@media (min-width: 769px) {
  .map-container {
    transform: scale(1.1);
  }
}
@media (min-width: 1200px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;    
  }
  .map-frame {
    order: 1;
  }

  .map-numbers {
    order: 2;
    display: flex;
    flex-direction: column;
  }
  .map-legend {
    order: 3;
    width: 100%;
    display: flex;
  }
  .map-btn {
    order: 4;
    width: 100%;
    display: flex;
    margin: var(--margin-s);
    justify-content: center;
  }
}
</style>
