<script>
import SocialLink from './SocialLink.svelte'

const backToTop = (_) => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }
}
</script>

<div class="wrapper">
  <div>
    <strong style= "margin: auto; max-width: 40rem;">
      Dona per rendere felici e sani i bambini:
    </strong>
    <p class="large-padding"><a href="https://dona.missionbambini.org/dove-serve-di-piu/~mia-donazione" target="_blank">con carta di credito su missionbambini.org</a></p>

    <p>con bonifico bancario intestato a Mission Bambini ETS</p>
    <p>IBAN IT19Q0306909606100000067111</p>
  </div>
  <div>
    <p>Seguici su</p>
    <SocialLink color="#FFC129" />
  </div>
  <div>
    <strong>Mission Bambini ETS</strong>
    <p><a href="//missionbambini.org/" target="_blank">www.missionbambini.org</a></p>
    <p>Via Ronchi 17, 20134 Milano</p>
    <p>Tel. +39 02 21.00.241</p>
    <p>E-mail: <a href="mailto:info@missionbambini.org" target="_blank">info@missionbambini.org</a></p>
    <p>Codice Fiscale: 13022270154</p>
    <p>Partita Iva: IT05494870966</p>
  </div>
  <div style= "margin: auto; max-width: 40rem;">
    <strong style="font-size: 16px;"
      >Ai sensi dell’articolo 4 del Codice del Terzo Settore, Mission Bambini è un Ente del Terzo
      Settore (ETS) perseguendo senza scopo di lucro finalità civiche, solidaristiche e di utilità
      sociale.</strong>
  </div>
</div>

<style>
.wrapper {
  padding: 3rem 1rem;
  margin: 0 auto;
  text-align: center;
  font-size: var(--font-body);
  font-weight: 400;
  font-family: 'Work Sans', sans-serif;
  line-height: 1.25rem;
  background-color: #0000ff;
  color: white;
}

.large-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

p {
  padding-bottom: 0.5rem;
}

a {
  text-decoration: underline;
}

div {
  padding-bottom: 3rem;
}

</style>
