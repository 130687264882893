<script>
import Module from './Module.svelte'
import H2 from './H2.svelte'
import P from './P.svelte'
import Img from './Img.svelte'
import Gallery from './Gallery.svelte'
import Iframe from './Iframe.svelte'
import AreaChart from './AreaChart.svelte'
import SVG from './SVG.svelte'
import BarChart from './BarChart.svelte'
import Quote from './Quote.svelte'
import VideoLoop from './VideoLoop.svelte'
import TreeMap from './TreeMap.svelte'
import Map from './Map.svelte'
import AnimatedNumber from './AnimatedNumber.svelte'
import LinkBtn from './LinkBtn.svelte'
import SymbolsGrid from './SymbolsGrid.svelte'
import ProjectsGrid from './ProjectsGrid.svelte'
import DonutChart from './DonutChart.svelte'
import Divider from './Divider.svelte'

export let backgroundColor = '#FFF'
export let fullWidth = false
export let color = '#000'
export let modules
export let padded = { top: false, bottom: false }
export let ref = ''

const MODULE_TYPES = {
  h2: H2,
  p: P,
  divider: Divider,
  img: Img,
  iframe: Iframe,
  gallery: Gallery,
  areaChart: AreaChart,
  svg: SVG,
  barChart: BarChart,
  quote: Quote,
  videoLoop: VideoLoop,
  treeMap: TreeMap,
  donut: DonutChart,
  map: Map,
  'animated-number': AnimatedNumber,
  btn: LinkBtn,
  symbols: SymbolsGrid,
  'projects-grid': ProjectsGrid,
}
</script>

<section
  class:fullWidth
  class:padded-top={padded.top}
  class:padded-bottom={padded.bottom}
  data-scroll={ref}
  style="background-color: {backgroundColor}; color: {color};">
  {#each modules as module}
    <Module moduleType={MODULE_TYPES[module.type]} {module} />
  {/each}
</section>

<style>
section {
  padding: var(--margin-m);
  overflow-x: hidden;
}

.padded-top {
  padding-top: 0rem;
}

.padded-bottom {
  padding-bottom: 0rem;
}

@media (min-width: 992px) {
  section {
    margin: 0 auto;
    padding: var(--margin-m) 0;
    max-width: 50%;
  }
  section.fullWidth {
    max-width: 100%;
    padding: var(--margin-l) var(--margin-xxl);
  }
  section.fullWidth:last-of-type {
    padding: var(--margin-xl) 18rem;
  }
}
</style>
