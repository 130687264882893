<script>
export let countryName = 'camerun'
export let activeProjects = [0, 1, 1, 0, 1]
export let idx = 1
export let isRound = false
export let isEmpty = false  
</script>

<div class={`box ${isEmpty ? 'transparent' : ''}`}>
  {#if !isEmpty} 
    <p>{countryName.toUpperCase()}</p>
    <div class="rect-container">
      {#each activeProjects as project, i}
        <div class={`rectangle ${isRound ? 'roundCorner' : ''}`} class:health={i > 2} class:activeproj={project > 0} class:emergency={i === activeProjects.length - 1}>
          {#if idx == 0}
            {i + 1}
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
.box {
  padding:  var(--margin-s);
  height: 9.6rem;
  width: calc(50% - var(--margin-xs));  
  /* width: 20%; */
  background: #fff4db;
  border-radius: 8px;
  margin: 0 0 var(--margin-xs) 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box.transparent {
  background: transparent;
  display: none;
}
p {
  font-size: 1rem;
  line-height: 1.25rem;
}
.rect-container {
  display: flex;
  flex-wrap: wrap;
}

.rectangle {
  color: #00b7ff;
  border: 2px solid #00b7ff;
  height: 3rem;
  width: 1rem;
  margin-right: 2px;
  text-align: center;
}
.rectangle.roundCorner {
  border-radius: 20px;
}

.activeproj {
  color: white;
  background: #00b7ff;
}
.health {
  color: #007aff;
  border: 2px solid #007aff;
}
.activeproj.health {
  color: white;
  background: #007aff;
}
.emergency {
  color: #FA00FF;
  border: 2px solid #FA00FF;
}
.activeproj.emergency {
  color: white;
  background: #FA00FF;
}


@media (min-width: 768px) {
  .box {
    width: calc(20% - var(--margin-xs));    
  }
  .box.transparent {
    display: flex;
  }
}

/* @media (min-width: 992px) {
  .box {
    width: calc(20% - var(--margin-xs));
  }
} */


</style>
