<script>
import { data } from './data/contents.js'
import Hero from './components/Hero.svelte'
import Section from './components/Section.svelte'
import PreFooter from './components/PreFooter.svelte'
import Video from './components/Video.svelte'
import FixedHeader from './components/FixedHeader.svelte'

const scrollToDataScroll = (id) => {
  const el = document.querySelector(`[data-scroll='${id}']`)
  const y = el.offsetTop - 71
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  })
}
</script>

<main>
  <FixedHeader />
  <div class="inner">
    <!-- <div
      class="placeholder"
      style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: -10;">
      <img
        src={'./assets/blurred.jpg'}
        alt="immagine di placeholder in attesa del video"
        style="width: 100%; height: 100%; object-fit: cover;" />
    </div> -->
    <!-- !!! Removed temporary -->
    <!-- <Video {...data['sectionsAttachedVideo']} /> -->
  </div>
  <Hero {...data['hero']} />
  <nav>
    <a on:click={() => scrollToDataScroll('risultati')}><span>I risultati del <b>2022</b></span></a>
    <a on:click={() => scrollToDataScroll('risorse')}><span>Le risorse del <b>2022</b></span></a>
    <a on:click={() => scrollToDataScroll('impegnodal2000')}
      ><span>Il nostro impegno <b>dal 2000</b></span></a>
  </nav>
  {#each data['sections'] as section}
    <Section {...section} />
  {/each}
  <PreFooter {...data['preFooter']} />
</main>

<style>
main {
  height: 100%;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  column-gap: 60px;
}
nav a {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #0000ba;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  font-size: 18px;
  font-family: 'Saira Semi Condensed', sans-serif;
  margin: 12px 40px;
  line-height: 22px;
}
nav,
nav a {
  height: 100px;
}
nav a:hover {
  color: #0000ff;
}
@media screen and (max-width: 1024px) {
  nav {
    height: 176px;
    flex-direction: column;
    padding: 16px;
  }
  nav a {
    flex-grow: 1;
    font-size: 14px;
  }
}
.inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
:global(:root) {
  /* 10px */
  --margin-xs: 0.556rem;
  /* 14px */
  --margin-s: 0.778rem;
  --margin: 1em;
  --margin-m: 1.333em;
  --margin-l: 1.555rem;
  --margin-xl: 4.444rem;
  --margin-xxl: 5rem;
  --blue-primary-700: #00007c;
  --blue-primary-600: #0000ba;
  --blue-primary-500: #0000ff;
  --blue-primary-400: #007aff;
  --blue-primary-300: #00b7ff;
  --yellow-secondary-400: #ffdb48;
  /* 18px */
  --font-body: 1rem;
  /* 16px */
  --font-body-sm: 0.888rem;
  /* 24px */
  --font-title-xs: 1.333rem;
  /* 48px */
  --font-title: 2.666rem;
  /* 28px */
  --font-title-sm: 1.555rem;
  /* 80px */
  --font-super: 4.444rem;
}
</style>
